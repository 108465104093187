<template>
  <section class="carousel" aria-label="Gallery">
    <ol class="carousel__viewport">
      <li id="carousel__slide1" tabindex="0" class="carousel__slide">
        <div class="carousel__snapper">
          <div class="carousel__content">
            <div class="carousel__image">
              <img src="@/images/step1.png" />
            </div>
            <div class="carousel__counter">
              1
            </div>
            <div class="carousel__how">
              <div class="carousel__title">Create an account</div>
              <div class="carousel__description">
                Create an account before you can post referral opportunities.
              </div>
            </div>
          </div>
          <div class="carousel__content__mobile">
            <div class="carousel__image__mobile">
              <img src="@/images/step1.png" />
              <div class="carousel__title">Create an account</div>
            </div>
          </div>
        </div>
      </li>

      <li id="carousel__slide2" tabindex="0" class="carousel__slide">
        <div class="carousel__snapper">
          <div class="carousel__content">
            <div class="carousel__image">
              <img src="@/images/step2-alumni.png" />
            </div>
            <div class="carousel__counter">
              2
            </div>
            <div class="carousel__how">
              <div class="carousel__title">Post opportunity</div>
              <div class="carousel__description">
                Post information about the referral opportunity in a few simple
                steps.
              </div>
            </div>
          </div>
          <div class="carousel__content__mobile">
            <div class="carousel__image__mobile">
              <img src="@/images/step2-alumni.png" />
              <div class="carousel__title">Post opportunity</div>
            </div>
          </div>
        </div>
      </li>

      <li id="carousel__slide3" tabindex="0" class="carousel__slide">
        <div class="carousel__snapper">
          <div class="carousel__content">
            <div class="carousel__image">
              <img src="@/images/step3-alumni.png" />
            </div>
            <div class="carousel__counter">
              3
            </div>
            <div class="carousel__how">
              <div class="carousel__title">Receive applications</div>
              <div class="carousel__description">
                You can check out their profile and resume to select the best
                match.
              </div>
            </div>
          </div>
          <div class="carousel__content__mobile">
            <div class="carousel__image__mobile">
              <img src="@/images/step3-alumni.png" />
              <div class="carousel__title">Receive applications</div>
            </div>
          </div>
        </div>
      </li>

      <li id="carousel__slide4" tabindex="0" class="carousel__slide">
        <div class="carousel__snapper">
          <div class="carousel__content">
            <div class="carousel__image">
              <img src="@/images/step4-alumni.png" />
            </div>
            <div class="carousel__counter">
              4
            </div>
            <div class="carousel__how">
              <div class="carousel__title">Applications accepted</div>
              <div class="carousel__description">
                You can schedule a talk with them for final decision.
              </div>
            </div>
          </div>
          <div class="carousel__content__mobile">
            <div class="carousel__image__mobile">
              <img src="@/images/step4-alumni.png" />
              <div class="carousel__title">Accept applications</div>
            </div>
          </div>
        </div>
      </li>
    </ol>
    <aside class="carousel__navigation">
      <ol class="carousel__navigation-list">
        <li class="carousel__navigation-item">
          <a href="#carousel__slide1" class="carousel__navigation-button"
            >Go to slide 1</a
          >
        </li>
        <li class="carousel__navigation-item">
          <a href="#carousel__slide2" class="carousel__navigation-button"
            >Go to slide 2</a
          >
        </li>
        <li class="carousel__navigation-item">
          <a href="#carousel__slide3" class="carousel__navigation-button"
            >Go to slide 3</a
          >
        </li>
        <li class="carousel__navigation-item">
          <a href="#carousel__slide4" class="carousel__navigation-button"
            >Go to slide 4</a
          >
        </li>
      </ol>
    </aside>
  </section>
</template>

<script>
import { ref } from 'vue';

export default {
  setup() {
    const isAlumni = ref(true);

    return {
      isAlumni,
    };
  },
};
</script>

<style scoped>
::-webkit-scrollbar {
  scrollbar-width: 0;
}

ol,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

.carousel {
  padding-top: 30%;
}

.carousel__viewport {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  overflow-x: scroll;
  counter-reset: item;
  scroll-behavior: auto;
  scroll-snap-type: x mandatory;
}

.carousel__slide {
  position: relative;
  flex: 0 0 100%;
  width: 100%;
  counter-increment: item;
}

.carousel__snapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  scroll-snap-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel__content {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10%;
}

@media (prefers-reduced-motion: reduce) {
  .carousel__snapper {
    animation-name: none;
  }
}

.carousel:hover .carousel__snapper,
.carousel:focus-within .carousel__snapper {
  animation-name: none;
}

.carousel__navigation {
  position: absolute;
  right: 0;
  left: 0;
  text-align: center;
}

.carousel__navigation-list,
.carousel__navigation-item {
  display: inline-block;
}

.carousel__navigation-button {
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  background-color: #333;
  background-clip: content-box;
  border: 0.25rem solid transparent;
  border-radius: 50%;
  font-size: 0;
  transition: transform 0.1s;
}

.carousel__prev,
.carousel__next {
  position: absolute;
  top: 0;
  margin-top: 37.5%;
  width: 4rem;
  height: 4rem;
  transform: translateY(-50%);
  border-radius: 50%;
  font-size: 0;
  outline: 0;
}

.carousel::before,
.carousel__prev {
  left: -1rem;
}

.carousel::after,
.carousel__next {
  right: -1rem;
}

.carousel::before,
.carousel::after {
  content: '';
  z-index: 1;
  background-color: #333;
  background-size: 1.5rem 1.5rem;
  background-repeat: no-repeat;
  background-position: center center;
  color: #fff;
  font-size: 2.5rem;
  line-height: 4rem;
  text-align: center;
  pointer-events: none;
}

.carousel::before {
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpolygon points='0,50 80,100 80,0' fill='%23fff'/%3E%3C/svg%3E");
}

.carousel::after {
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpolygon points='100,50 20,100 20,0' fill='%23fff'/%3E%3C/svg%3E");
}

.carousel__image {
  background-color: white;
  position: relative;
  left: -10vw;
  padding: 6vw;
  border-radius: 30%;
  margin-right: -10vw;
}

.carousel__counter {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 65px;
  padding: 20px;
}

.carousel__how {
  max-width: 220px;
  position: relative;
  top: 3vh;
}

.carousel__title {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 30px;
  color: #40166d;
}

.carousel__description {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #40166d;
}

.carousel__content__mobile {
  display: none;
}

@media screen and (max-width: 1500px) {
  .carousel__navigation {
    bottom: 5%;
  }
}

@media screen and (max-width: 800px) {
  .carousel__navigation {
    bottom: 5%;
  }
}

@media screen and (max-width: 600px) {
  .carousel__content__mobile {
    display: block;
    text-align: center;
    margin-top: 20%;
  }

  .carousel__image__mobile {
    background-color: white;
    padding: 6vw;
    border-radius: 30%;
  }

  .carousel__content {
    display: none;
  }

  .carousel__navigation {
    bottom: 10%;
  }
}

* {
  box-sizing: border-box;
  scrollbar-color: transparent transparent; /* thumb and track color */
  scrollbar-width: 0px;
}

*::-webkit-scrollbar {
  width: 0;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background: transparent;
  border: none;
}
</style>
