import { db } from '@/firebase/config'

const getOfficialData = async (alumniNum, studentNum, oppNum) => {
  try {
    let alumniCounter = 10;
    let studentCounter = 30;
    await db.collection("Stats").doc("profiles").get().then(doc => {
      studentCounter += doc.data().juniorCount;
      alumniCounter += doc.data().seniorCount;
    })
    let oppCounter = 0;
    await db.collection("Stats").doc("opportunities").get().then(doc => {
      oppCounter += doc.data().count;
    })
    alumniNum.value = alumniCounter;
    studentNum.value = studentCounter;
    oppNum.value = oppCounter;
  } catch (error) {
    alert(error)
  }

};

export default getOfficialData
